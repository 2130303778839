body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.child-items {
  padding-left: 3em;
  padding-bottom: 1em;
}

.sms_report_filters_parent_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin: 0 15px;
}

.sms_report_filter_container {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 12px;
}

.rmsc .dropdown-container{
  width: 250px;
}

.rmsc .dropdown-container {
  padding: 2px;
}

.rmsc .dropdown-heading, .rmsc .dropdown-heading .dropdown-heading-value {
  height: fit-content!important;
}

.rmsc .dropdown-heading .dropdown-heading-value span {
  white-space: break-spaces;
}

.react-datepicker__input-container input{
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.action_button {
  background-color: #003E82;
  opacity: 0.85;
  color: white;
  padding: 5px 8px;
  border-radius: 5px;
  border: solid 1px #003E82;
  cursor: pointer;
}

.content_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdt_TableCol {
  color: white;
  font-weight: bold;
}

.rdt_TableCol .rdt_TableCol_Sortable div {
  text-overflow: unset;
  min-width: fit-content;
  white-space: break-spaces;
}

.rdt_TableCol[data-column-id="1"], .rdt_TableCol[data-column-id="2"] {
  min-width: 150px;
}

.rdt_TableCol[data-column-id="1"], .rdt_TableCol[data-column-id="2"], .rdt_TableCol[data-column-id="3"] {
  background-color: #5a8cc4;
}

.rdt_TableCol[data-column-id="4"], .rdt_TableCol[data-column-id="6"] {
  background-color: #689b48;
}

.rdt_TableCol[data-column-id="5"], .rdt_TableCol[data-column-id="7"] {
  background-color: #98c77b;
}

.rdt_TableCol[data-column-id="8"], .rdt_TableCol[data-column-id="10"] {
  background-color: #c57728;
}

.rdt_TableCol[data-column-id="9"], .rdt_TableCol[data-column-id="11"] {
  background-color: #c58a4e;
}

.rdt_TableCol[data-column-id="12"], .rdt_TableCol[data-column-id="14"] {
  background-color: #b93737;
}

.rdt_TableCol[data-column-id="13"], .rdt_TableCol[data-column-id="15"] {
  background-color: #c75151;
}

.rdt_TableCol[data-column-id="16"] {
  background-color: #90b5ea;
}

.rdt_TableCol[data-column-id="17"] {
  background-color: #5790e3;
}

.rdt_TableCol[data-column-id="18"] {
  background-color: #4477c2;
}

.rdt_TableCol[data-column-id="19"] {
  background-color: #2b64bb;
}

.email_table, .sms_table, .whatsapp_table {
  width: 90vw;
}

.email_table .rdt_TableCol, .sms_table .rdt_TableCol, .whatsapp_table .rdt_TableCol {
  background-color: transparent;
  color: black;
  font-weight: 400;
  min-width: fit-content;
  white-space: break-spaces;
  text-overflow: unset;
}

.email_table .rdt_TableCol[data-column-id="1"], .sms_table .rdt_TableCol[data-column-id="1"], .whatsapp_table .rdt_TableCol[data-column-id="1"] {
  min-width: 150px;
}
/* Formularios de Creación y Edición de Cientes */
.child-items > div{
  display: block;
}
